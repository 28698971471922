<template>
  <div class="bottom">
    <div class="bottom-box">
      <div class="box-top">
        <div class="box-information">
          <div class="inforBox" v-for="(message, index) in infortmation" :class="heightList[index]" :key="'massage' + index">
            <div class="fontOne">{{ message.title }}</div>
            <div class="lineShort"></div>
            <div v-for="(message2, index) in message.infor" :key="'message2' + index">
              <div class="fontTwo">
                <a class="fontTwo" v-if="message2.href != ''" :href="message2.href"> {{ message2.name }}</a>
                <template v-else>{{ message2.name }}</template>

              </div>
            </div>
          </div>
        </div>
        <!-- 二维码 -->
        <div class="qRBox">
          <div class="qRBox-fontOne">关注我们</div>
          <div class="qRBox-box"><img class="qRBox-image" src="../assets/qcode.png" alt="" /></div>
          <div class="qRBox-fontTwo">关注我们</div>
        </div>
      </div>

      <div class="box-line"></div>

      <div class="box-between">
        <div class="betweenTitle">友情链接</div>
        <div class="lineFriend">
          <div class="lineFriend-line"></div>
        </div>

        <div class="lableBox" >
          <div class="lableOne" @click="skipUrl(l)" v-for="(l,index) in loadFriendLinksData" :key="index">{{l.name}}</div>
        </div>
      </div>
      <div class="box-bottom">
        <div class="lastFont">
          <div class="box-bottomOne"> Copyright &copy; 江苏一道云科技发展有限公司</div>
          <a class="box-bottomTwo"  href="https://beian.miit.gov.cn/" target="_blank">苏ICP备17041983号-10</a>
        </div>
      </div>
    </div>
    <a-back-top />
  </div>
</template>

<script>


export default {
  data() {
    return {
      loadFriendLinksData: [],
      message: 'hello world',
      infortmation: [
        {
          title: '公司信息',
          infor: [{name:'江苏一道云科技发展有限公司',href:""}, {name:'地址：江苏省无锡市滨湖区太湖西大道2188号200室',href:""}],
        },
        {
          title: '联系方式',
          infor: [{name:'服务热线：0510-85730086',href:""}, {name:'邮箱：jsydy@1daoyun.com',href:""}],
        },
      ],
      heightList: ['height1', 'height2'],
    };
  },
  created() {
    this.loadFriendLinks();
  },
  methods: {
    async loadFriendLinks() {
      let loadFriendLinksUrl = `/home/loadFriendLinks`;
      let result = await this.axios.get(loadFriendLinksUrl);
      this.loadFriendLinksData = result.data.data;
      console.log(this.loadFriendLinksData);
    },
    skipUrl(l){
      // console.log(l);
      window.open(l.link);  
    }
  },
};
</script>

<style scoped>
.bottom {
  width: 100%;
  min-width: 1200px;
  height: 464px;
  background-color: #24262c;
}
.bottom-box {
  width: 1200px;
  height: 464px;
  margin: 0 auto;
}
.box-top {
  width: 1200px;
  height: 227px;
  display: flex;
}
.box-line {
  border-bottom: 2px solid #3a3c42;
}
.box-between {
  width: 1200px;
  height: 178px;
}

/* 3个一起的盒子 */
.box-information {
  width: 1070px;
  height: 227px;
  margin: 0 auto;
  display: flex;
}
/* 一个 */
.inforBox {
  /* width: 443px; */
  width: 286px;
  height: 227;
  margin: 30px 0 30px 0;
}

.inforBox.height1 {
  /* width: 443px; */
  width: 470px;
}

.fontOne {
  width: 285px;
  height: 17px;
  font-size: 17px;
  color: #ffffff;
  line-height: 17px;
  margin-bottom: 15px;
}
.lineShort {
  width: 33px;
  height: 2px;
  background-color: #fff;
  margin-bottom: 15px;
}
.fontTwo {
  width: 385px;
  height: 14px;
  font-size: 14px;
  color: #ffffff;
  line-height: 14px;
  margin-bottom: 15px;
}
/* 二维码 */
.qRBox {
  /* width: 185px; */
  width: 130px;
  height: 227px;
  padding: 30px 0 30px 0px;
}
.qRBox-fontOne {
  width: 90px;
  height: 14px;
  font-size: 14px;
  color: #ffffff;
  line-height: 14px;
  margin-bottom: 15px;
  text-align: center;
}
.qRBox-box {
  width: 90px;
  height: 90px;
}
.qRBox-image {
  width: 90px;
  height: 90px;
}
.qRBox-fontTwo {
  width: 90px;
  height: 14px;
  font-size: 14px;
  color: #ffffff;
  line-height: 14px;
  margin-top: 15px;
  text-align: center;
}
/* 第二大部分 */
.betweenTitle {
  width: 100%;
  height: 30px;
  text-align: center;
  font-size: 17px;
  line-height: 18px;
  box-sizing: border-box;
  color: #fff;
  padding-top: 10px;
}
.lineFriend {
  height: 34px;
  width: 100%;
}
.lineFriend-line {
  width: 30px;
  height: 2px;
  background-color: #fff;
  margin: 15px auto;
}
.lableBox {
  width: 1200px;
  /* height: 80px; */
  display: flex;
  text-align: center;
  flex-wrap: wrap;
}
.lableOne {
  width: 300px;
  height: 25px;
  line-height: 25px;
  color: #fff;
  cursor: pointer;
}
/* 这是第三部分 */
.box-bottom {
  width: 1200px;
  /* height: 60px; */
}
.lastFont {
  display: flex;
  height: 60px;
  width: 600px;
  margin: 0 auto;
  color: #fff;
}
.box-bottomOne {
  text-align: center;
  line-height: 60px;
  margin-right: 40px;
}
.box-bottomTwo {
  text-align: center;
  line-height: 60px;
}
a{
  color: white;
}
</style>
