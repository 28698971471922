<template>
  <div class="repeatcontainer">
    <div class="repeatmyheader">
      <!-- logo AND 导航 -->
      <div class="repeatheaderBox">
        <!-- logo -->
        <a href="/">
          <div class="repeattitleBox">
            <div class="repeatlogo"><img class="repeatlogoImage" src="../assets/header-logo.png" alt="" /></div>
            <div class="repeatfont">一道云</div>
          </div>
        </a>
        <!-- 导航 -->
        <div class="repeatnavigation">
          <div class="repeatnavProduct" id="s1" :style="s1.back">
            <div class="repeattext">
              <div class="nav-name" >产品服务</div>
              <div style="font-size: 12px"><a-icon type="down" style="margin-top: 5px" /></div>
            </div>
            <!-- <img class="repeatimage" src="../assets/arrow.png" alt="" v-if="nav.link.length > 0 ? false : true" /> -->
            <div class="triangle" :style="s1.dis"></div>
          </div>
          <div class="repeatnavProduct" id="s2" :style="s2.back">
            <div class="repeattext">
              <div class="nav-name" >解决方案</div>
              <div style="font-size: 12px"><a-icon type="down" style="margin-top: 5px" /></div>
            </div>
            <!-- <img class="repeatimage" src="../assets/arrow.png" alt="" v-if="nav.link.length > 0 ? false : true" /> -->
            <div class="triangle" :style="s2.dis"></div>
          </div>
          <div class="repeatnavProduct" id="s3" :style="s3.back">
            <div class="repeattext">
              <div class="nav-name" >资讯资源</div>
              <div style="font-size: 12px"><a-icon type="down" style="margin-top: 5px" /></div>
            </div>
            <!-- <img class="repeatimage" src="../assets/arrow.png" alt="" v-if="nav.link.length > 0 ? false : true" /> -->
            <div class="triangle" :style="s3.dis"></div>
          </div>
          <div class="repeatnavProduct" id="s4" :style="s4.back">
            <div class="repeattext">
              <div class="nav-name" >关于我们</div>
              <div style="font-size: 12px"><a-icon type="down" style="margin-top: 5px" /></div>
            </div>
            <!-- <img class="repeatimage" src="../assets/arrow.png" alt="" v-if="nav.link.length > 0 ? false : true" /> -->
            <div class="triangle" :style="s3.dis"></div>
          </div>
          <!-- 产品服务下拉框 -->
          <div class="repeatproduct" id="product-one" @mouseenter="mouseOver(1)" @mouseleave="mouseLeave(1)">
            <div class="repeatproductBox">
              <div class="repeatproductName">产品服务</div>
              <div class="repeatline"></div>
              <div class="repeatproductPlateBox-Two">
                <div class="repeatproductPlate" v-for="(producttwo, index) in productType" :key="'product' + index">
                  <img class="repeatimage-Two" @click="skipLink(producttwo.link)" :src="producttwo.url" alt="" />
                  <div class="repeatproductFontTwo" @click="skipLink(producttwo.link)">{{ producttwo.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 解决方案下拉框 -->
          <div class="repeatproduct" id="product-two" @mouseenter="mouseOver(2)" @mouseleave="mouseLeave(2)">
            <div class="repeatproductBox">
              <div class="repeatproductName">解决方案</div>
              <div class="repeatline"></div>
              <div class="repeatproductPlateBox-Two">
                <div class="repeatproductPlate" v-for="(producttwo, index) in productTypeTwo" :key="'producttwo' + index">
                  <img class="repeatimage-Two" @click="skipLink(producttwo.link)" :src="producttwo.url" alt="" />
                  <div class="repeatproductFontTwo" @click="skipLink(producttwo.link)">{{ producttwo.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 咨询资源下拉框 -->
          <div class="repeatproduct" id="product-three" @mouseenter="mouseOver(3)" @mouseleave="mouseLeave(3)">
            <div class="repeatproductBox">
              <div class="repeatproductName">资讯资源</div>
              <div class="repeatline"></div>
              <div class="repeatproductPlateBox-Two">
                <div class="repeatproductPlate" v-for="(producttwo, index) in productTypeThree" :key="'productthree' + index">
                  <img class="repeatimage-Two" @click="skipLink(producttwo.link)" :src="producttwo.url" alt="" />
                  <div class="repeatproductFontTwo" @click="skipLink(producttwo.link)">{{ producttwo.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="repeatproduct" id="product-four" @mouseenter="mouseOver(4)" @mouseleave="mouseLeave(4)">
            <div class="repeatproductBox">
              <div class="repeatproductName">关于我们</div>
              <div class="repeatline"></div>
              <div class="repeatproductPlateBox-Two">
                <div class="repeatproductPlate" v-for="(productfour, index) in productTypeFour" :key="'productfour' + index">
                  <img class="repeatimage-Two" @click="skipLink(productfour.link)" :src="productfour.url" alt="" />
                  <div class="repeatproductFontTwo" @click="skipLink(productfour.link)">{{ productfour.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tipTitle: '友情提示',
      message: 'hello world',
      productType: [
        { url: require('../assets/header/sxpt.png'), name: '实训平台', link: 'training_platform' },
        { url: require('../assets/header/js.png'), name: '竞赛平台' ,link: 'gameManage' },
        { url: require('../assets/header/ygpt.png'), name: '云管平台', link: 'couldManage' },
      ],
      productTypeTwo: [
        { url: require('../assets/solve-one.png'), name: '专业实训室',link: 'trainingRoom' },
        // { url: require('../assets/solve-two.png'), name: '资源开发' },
        { url: require('../assets/solve-two.png'), name: '资源开发' ,link: 'resourceDevelopment'},
        { url: require('../assets/solve-three.png'), name: '工匠工坊', link: 'craftsman_workshop' },
        { url: require('../assets/solve-four.png'), name: '培训认证', link: 'trainingCertification' }
      ],
      productTypeThree: [
        { url: require('../assets/newsResource.png'), name: '新闻资讯', link: 'news_information' },
        { url: require('../assets/uploadResource.png'), name: '资源下载', link: 'resouce' },
      ],
      productTypeFour: [
        { url: require('../assets/header/sxpt.png'), name: '关于我们', link: 'About' },
        { url: require('../assets/header/js.png'), name: '员工风采' ,link: 'staffPresence' },
        { url: require('../assets/header/ygpt.png'), name: '加入我们', link: 'joinUs' },
      ],
      s1: { back: '', dis: '' },
      s2: { back: '', dis: '' },
      s3: { back: '', dis: '' },
      s4: { back: '', dis: '' },
    };
  },
  created() {},
  methods: {
    skipLink(link) {
      this.$router.push(link);
    },
    mouseOver(data) {
      if (data == 1) {
        this.s1.back = 'background-color: #2e71fe;';
        this.s1.dis = 'display: block;';
        this.s2 = {};
        this.s3 = {};
        this.s4 = {};
      }
      if (data == 2) {
        this.s2.back = 'background-color: #2e71fe;';
        this.s2.dis = 'display: block;';
        this.s1 = {};
        this.s3 = {};
        this.s4 = {};
      }
      if (data == 3) {
        this.s3.back = 'background-color: #2e71fe;';
        this.s3.dis = 'display: block;';
        this.s1 = {};
        this.s2 = {};
        this.s4 = {};
      }
      if (data == 4) {
        this.s4.back = 'background-color: #2e71fe;';
        this.s4.dis = 'display: block;';
        this.s1 = {};
        this.s2 = {};
        this.s3 = {};
      }
      console.log(data);
    },
    mouseLeave(data) {
      (this.s1 = { back: '', dis: '' }), (this.s2 = { back: '', dis: '' }), (this.s3 = { back: '', dis: '' }), 
      (this.s4 = { back: '', dis: '' })
      ,console.log(data);
    },
    // one() {
    //   console.log(this.showProduct);
    // },
  },
};
</script>

<style scoped lang="less">
.nav-name{
  cursor: pointer;
  margin-right: 5px
}
.repeatmyheader {
  width: 100%;
  height: 73px;
  display: flex;
  justify-content: center;
  position: relative;
}
.repeatheaderBox {
  width: 100%;
  height: 73px;
  display: flex;
  justify-content: space-around;
}
.repeattitleBox {
  width: 146px;
  height: 33px;
  display: flex;
  margin: 20px 0 20px 0;
  cursor: pointer;
}

.repeattitleBox .repeatlogo .repeatlogoImage {
  width: 66px;
  height: 33px;
  margin-right: 10px;
}
.repeattitleBox .repeatfont {
  width: 75px;
  height: 24px;
  font-size: 20px;
  font-weight: bold;
  color: #2e71fe;
}
/* 导航 */
.repeatnavigation {
  width: 580px;
  height: 72px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
}

/* 第一个 */
.repeatnavigation .repeatnavProduct {
  width: 145px;
  height: 73px;
  color: #000;
  font-weight: 600;
  /* display: flex; */
  /* justify-content: center;
  align-items: center; */
  background-color: rgb(255, 255, 255);
}
.repeatnavigation .repeatnavProduct .repeatimage {
  width: 10px;
  height: 6px;
  margin: 3px 0 0 0;
}
.repeatnavigation .repeatnavProduct .repeattext {
  /* width: 67px;
  height: 23px; */
  font-size: 15px;
  display: flex;
  justify-content: center;
  margin-top: 25px;
  /* align-items: center; */
  /* line-height: 80px; */
}
.repeatnavigation .repeatnavProduct:hover {
  background-color: #2e71fe;
  color: #FFF;
}
.repeatnavigation .repeatnavProduct:hover .triangle {
  display: block;
}
.triangle {
  display: none;
  border: 6px solid transparent;
  border-bottom: 6px solid white;
  height: 0;
  width: 0;
  margin: auto;
  bottom: 0;
  margin: 13.5px 0 0 70px;
  position: absolute;
  z-index: 999;
}
/* .repeatnavProduct:hover::after {
  content: '';
  border: 6px solid transparent;
  border-bottom: 6px solid white;
  height: 0;
  width: 0;
  display: block;
  margin: 60px 0 0 0;
  position: absolute;
} */
/* 这是分类的板块 */
.repeatproduct {
  top: 100%;
  min-width: 1200px;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 -3px 15px #d3d1d1; /*上边阴影*/
  position: absolute;
  z-index: 1;
  left: 0px;
}
.repeatproductBox {
  margin: 0 auto;
  width: 1200px;
  height: 200px;
}
.repeatproduct .repeatproductBox .repeatproductName {
  width: 63px;
  height: 51px;
  color: #000;
  font-weight: 600;
  padding: 16px 0 16px 0;
}
.repeatproduct .repeatline {
  width: 1200px;
  border-top: 1px solid rgb(231, 231, 231);
}
.repeatproduct .repeatproductPlateBox {
  width: 1200px;
  height: 248px;
  display: flex;
  justify-content: space-around;
  padding: 0 40px;
}
.repeatproductPlateOne {
  cursor: pointer;
}
.repeatproductPlateBox .repeatproductPlateOne {
  width: 188px;
  height: 168px;
  border: 1px solid rgb(239, 239, 239);
  border-radius: 5%;
  margin: auto;
}
.repeatproductPlateOne .repeatimageBox {
  /* width: 95px; */
  height: 90px;
  text-align: center;
  margin: 13px auto 8px auto;
}
.repeatproductPlateOne .repeatimageBox img {
  margin-left: 10px;
}
.repeatproductPlateOne .repeatproductFont {
  /* width: 74px; */
  /* height: 20px; */
  text-align: center;
  font-size: 17px;
  font-weight: 550;
  margin: auto;
}

/* 第二块 */
.repeatproductPlateBox-Two {
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
  padding: 20px 0;
}
.repeatproductPlate {
  height: 80px;
  display: flex;
  cursor: pointer;
}
.repeatproductPlate .repeatimage-Two {
  display: flex;
  width: 50px;
  height: 46px;
  margin-top: 18px;
}
.repeatproductFontTwo {
  font-size: 17px;
  color: #000;
  height: 80px;
  padding-left: 20px;
  line-height: 80px;
  font-weight: 400;
}
/* 第三块 */
.repeatproducts {
  top: 100%;
  /* min-width: 1200px; */
  width: 300px;
  height: 176px;
  margin: 0 0 0 150px;
  background-color: #fff;
  box-shadow: 0 -5px 15px #d3d1d1; /*上边阴影*/
  position: absolute;
  z-index: 1;
}
.repeatproductPlateBoxs-Three {
  width: 300px;
  height: 176px;
  justify-content: space-around;
  flex-flow: wrap;
}
.repeatproductPlates {
  justify-content: center;
  display: flex;
  margin-top: 40px;
  cursor: pointer;
}
.repeatproductPlate .repeatimages-Three {
  width: 50px;
  height: 46px;
  /* margin-top: 18px; */
}
.repeatproductFontThrees {
  font-size: 18px;
  color: #000;
  margin-left: 20px;
  /* padding-left: 20px; */
  line-height: 28px;
  /* font-weight: 400; */
}
#product-one {
  display: none;
}
#product-two {
  display: none;
}
#product-three {
  display: none;
}
#product-four {
  display: none;
}
#s1:hover ~ #product-one {
  display: block;
}
#product-one:hover {
  display: block;
}

#s2:hover ~ #product-two {
  display: block;
}
#product-two:hover {
  display: block;
}
#s3:hover ~ #product-three {
  display: block;
}
#product-three:hover {
  display: block;
}
#s4:hover ~ #product-four {
  display: block;
}
#product-four:hover {
  display: block;
}
</style>
